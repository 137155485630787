import { useEffect, useState } from "react";
import "../../../css/Forms.css";

const URL = process.env.REACT_APP_API_URL;

const ExecuteProcedureForm = ({
  setIsProcedureFormActive,
  onExecute,
}) => {
  const [filters, setFilters] = useState([]);
  const [groups, setGroups] = useState([]);
  const [actionList, setActionList] = useState([]);
  const [clickedButtons, setClickedButtons] = useState({});

  // Add or remove an item from the action list. If there is no item add it. 
  // if there is an item with different action change current action. If it has same action delete it
  function toggleAction(action, type, item) {
    setActionList((prev) => {
      const exists = prev.find((entry) => entry.id === item.id && entry.type === type)

      if (!exists) return [...prev, { ...item, action, type }];
      else if (exists.action !== action) return prev.map((entry) => entry.id === item.id && entry.type === type ? { ...entry, action } : entry);
      else return prev.filter((entry) => !(entry.id === item.id && entry.type === type));
    });

    // Updates the background of  the buttons when clicked
    setClickedButtons((prev) => {
      return {
        ...prev,
        [item.id]: prev[item.id] === (action === 'apply' ? 'rgb(0, 150, 0)' : 'red') ? null : action === 'apply' ? 'rgb(0, 150, 0)' : 'red',
      };
    });
  }

  // Add or remove or update all items from the action list.
  function toggleAll(action, type, items) {
    setActionList((prev) => {
      const updatedList = [...prev];
      items.forEach((item) => {
        const exists = prev.find((entry) => entry.id === item.id && entry.type === type);

        if (!exists) {
          updatedList.push({ ...item, action, type });
        } 
        
        else if (exists.action !== action) {
          const index = updatedList.findIndex(
            (entry) => entry.id === item.id && entry.type === type
          );
          updatedList[index] = { ...exists, action };
        } 
        
        else {
          updatedList.splice(
            updatedList.findIndex((entry) => entry.id === item.id && entry.type === type),
            1
          );
        }
      });
      return updatedList;
    });

    // Updates the background of  the buttons when clicked
    setClickedButtons((prev) => {
      const updatedButtons = { ...prev };
      items.forEach((item) => {
        updatedButtons[item.id] =
          updatedButtons[item.id] === (action === "apply" ? "rgb(0, 150, 0)" : "red")
            ? null
            : action === "apply"
            ? "rgb(0, 150, 0)"
            : "red";
      });
      return updatedButtons;
    });
  }

  // Executes procedure
  async function executeProcedure(e) {
    e.preventDefault();
    onExecute(actionList);
    setIsProcedureFormActive(false);
  }

  // Fetch data for filters
  useEffect(() => {
    async function fetchData() {
      try {
        const filterResponse = await fetch(`${URL}/filters/groupless`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!filterResponse.ok) {
          alert("Error al obtener los filtros");
          throw new Error("Failed to fetch filters");
        }

        const filterData = await filterResponse.json();

        const groupResponse = await fetch(`${URL}/filter_groups/all_sorted`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
  
        if (!groupResponse.ok) {
          alert("Error al obtener lista de grupos");
          throw new Error("Failed to fetch groups");
        }
        const groupData = await groupResponse.json();

        setFilters(filterData);
        setGroups(groupData)

      } catch (err) {
        if (err.name === "TypeError") {
          alert("No se pudo conectar con el servidor.");
        } else {
          console.error("Error fetching data for ExecuteProcedureForm: ", err);
        }
      }
    }

    fetchData();
  }, []);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={executeProcedure}>
          <div>
            <h3 className="modal-title">Filtros</h3>
            <button type="button" onClick={() => toggleAll("apply", "filter", filters)} style={{ margin: "0.5rem" }}>
              Aplicar todos
            </button>
            <button type="button" onClick={() => toggleAll("remove", "filter", filters)} style={{ margin: "0.5rem" }}>
              Quitar todos
            </button>
            <div className="item-list">
              {filters.map((item) => (
                <div key={item.id} className="list-item checkbox-label">
                  <span><b>{item.name}</b></span>
                  <div>
                    <button 
                      type="button" onClick={() => toggleAction("apply", "filter", item)}
                      style={{margin:"0.1rem", backgroundColor: clickedButtons[item.id] === 'rgb(0, 150, 0)' ? 'rgb(0, 150, 0)' : ''}}>
                      Aplicar
                    </button>
                    <button 
                      type="button" onClick={() => toggleAction("remove", "filter", item)}
                      style={{margin:"0.1rem", backgroundColor: clickedButtons[item.id] === 'red' ? 'red' : ''}}>
                      Quitar
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h3 className="modal-title">Grupos</h3>
            <button type="button" onClick={() => toggleAll("apply", "group", groups)} style={{ margin: "0.5rem" }}>
              Aplicar todos
            </button>
            <button type="button" onClick={() => toggleAll("remove", "group", groups)} style={{ margin: "0.5rem" }}>
              Quitar todos
            </button>
            <div className="item-list">
              {groups.map((item) => (
                <div key={item.id} className="list-item checkbox-label">
                  <span><b>{item.name} ({item.count})</b></span>
                  <div>
                    <button 
                      type="button" onClick={() => toggleAction("apply", "group", item)}
                      style={{margin:"0.1rem", backgroundColor: clickedButtons[item.id] === 'rgb(0, 150, 0)' ? 'rgb(0, 150, 0)' : ''}}>
                      Aplicar
                    </button>
                    <button 
                      type="button" onClick={() => toggleAction("remove", "group", item)}
                      style={{margin:"0.1rem", backgroundColor: clickedButtons[item.id] === 'red' ? 'red' : ''}}>
                      Quitar
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="button-group">
            <button
              type="button"
              onClick={() => setIsProcedureFormActive(false)}
            >
              Cancelar
            </button>
            <button type="submit">Ejecutar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExecuteProcedureForm;
