import React, { useEffect, useState, useCallback } from "react";
import SaveFiltersForm from "./forms/FilterList/SaveFiltersForm";
import SaveCombinationForm from "./forms/FilterList/SaveCombinationForm";
import SaveGroupOfFilters from "./forms/FilterList/SaveGroupOfFilters";
import ExecuteProcedureForm from "./forms/FilterList/ExecuteProcedureForm";

const URL = process.env.REACT_APP_API_URL;

const FiltersList = ({
  table,
  filters,
  setData,
  setMeta,
  setFilters,
  onReset,
  onUpdateLoading,
}) => {
  const [filterList, setFilterList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [formData, setFormData] = useState(null);
  const [isSaveFormActive, setIsSaveFormActive] = useState(false);
  const [isDrawFormActive, setIsDrawFormActive] = useState(false);
  const [isFilterGroupFormActive, setIsFilterGroupFormActive] = useState(false);
  const [isProcedureFormActive, setIsProcedureFormActive] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState([]);

  // Temporal, remove when split into components
  const [styles, setStyles] = useState({});

  const applyFilterStyle = (filterId) => {
    setStyles((prev) => ({ ...prev, [`filter-${filterId}`]: "applied" }));
  };

  const removeFilterStyle = (filterId) => {
    setStyles((prev) => ({ ...prev, [`filter-${filterId}`]: "removed" }));
  };

  const applyGroupStyle = (groupId) => {
    setStyles((prev) => {
      const updatedStyles = { ...prev };
  
      filterList
        .filter((filter) => filter.groupId === groupId)
        .forEach((filter) => {
          updatedStyles[`filter-${filter.id}`] = "applied";
        });
  
      updatedStyles[`group-${groupId}`] = "applied";
      return updatedStyles;
    });
  };

  const removeGroupStyle = (groupId) => {
    setStyles((prev) => {
      const updatedStyles = { ...prev };
  
      filterList
        .filter((filter) => filter.groupId === groupId)
        .forEach((filter) => {
          updatedStyles[`filter-${filter.id}`] = "removed";
        });
  
      updatedStyles[`group-${groupId}`] = "removed";
      return updatedStyles;
    });
  };

  const getStyle = (id, type) => {
    const status = styles[`${type}-${id}`];
    if (status === "applied") return { backgroundColor: "lightgreen" };
    if (status === "removed") return { backgroundColor: "lightcoral" };
    return {};
  };

  // ------------------------- Filter related operations ------------------------- //

  // Renders form with save or update functionality
  async function handleForm(id) {
    if (id) {
      try {
        const filterData = await getFilter(id);
        filterData.filterId = id;
        setFormData(filterData);
      } 
      
      catch (err) {
        if (err.name === 'TypeError') {
          alert("No se pudo conectar con el servidor.");
        } else { 
          console.error("Failed handle form: ", err)
        }
      }
    }
    setIsSaveFormActive(true);
  }

  // Stores a new filter into the db
  async function saveFilter(formData) {
    try {
      const response = await fetch(`${URL}/filters/save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        alert("Error al guardar filtro");
        throw new Error("Failed to save filters");
      } 
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error saving filters: ", err);
      }
    } 
    
    finally {
      fetchData();
      setIsSaveFormActive(false);
      setFormData(null);
    }
  }

  // Updates a filter with the current filter data
  async function updateFilter(id, formData) {
    try {
      const response = await fetch(`${URL}/filters/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 404) {
        alert("Filtro no encontrado");
        throw new Error("Filter not found (update)");
      } 

      if (!response.ok) {
        alert("Error al actualizar filtro");
        throw new Error("Failed to update filter")
      }
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error updating filter: ", err);
      }
    } 
    
    finally {
      fetchData();
      setIsSaveFormActive(false);
      setFormData(null);
    }
  }

  // Deletes a filter from the db given an id
  async function deleteFilter(id) {
    const confirmDelete = window.confirm(`Estas a punto de borrar el filtro ${
      filterList.find((filter) => filter.id === id).name.toUpperCase()
    }`)
    if (!confirmDelete) return;
    
    try {
      const response = await fetch(`${URL}/filters/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        alert("Error al borrar filtro");
        throw new Error("Failed to delete filter");
      }
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error deleting filter: ", err);
      }
    } 
    
    finally {
      fetchData();
    }
  }

  // Obtains data from a specific group
  async function getFilter(id) {
    try {
      const response = await fetch(`${URL}/filters/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 404) {
        alert("Filtro no encontrado");
        throw new Error("Filter not found");
      } 

      if (!response.ok) {
        alert("Error al obtener datos del filtro");
        throw new Error("Failed to fetch filter");
      }

      const data = await response.json();
      return data;
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error fetching filter: ", err);
      }
      return null;
    }
  }

  // Saves a new filter with a specific combination data
  async function saveCombination(formData) {
    try {
      const response = await fetch(`${URL}/filters/draw/${table}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 404) {
        alert("Sorteo no encontrado");
        throw new Error("Filter not found (comb)");
      } 

      if (!response.ok) {
        alert("Error al guardar sorteo");
        throw new Error("Failed to save draw");
      } 
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error saving draw: ", err);
      }
    } 
    
    finally {
      fetchData();
      setIsDrawFormActive(false);
      setFormData(null);
    }
  }

  // ------------------------- Group of filters related operations ------------------------- //

  // Creates a group and fills it with filters
  async function createFilterGroup(name, columns, size, leader, onlyFirst) {
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/filters/save/group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          name: name, 
          columns: columns, 
          size: size,
          leader: leader, 
          onlyFirst: onlyFirst
        }),
      });

      if (!response.ok) {
        alert("Error al crear grupo de filtros");
        throw new Error("Failed to create group of filters");
      } 
    }

    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error saving filters: ", err);
      }
    }

    finally {
      onUpdateLoading(false);
      setIsFilterGroupFormActive(false);
      setFormData(null);
      fetchData();
    }
  }
  
  // Displays filters under a group or not
  function toggleGroup(groupId) {
    setExpandedGroups((prevExpandedGroups) =>
      prevExpandedGroups.includes(groupId)
        ? prevExpandedGroups.filter((id) => id !== groupId)
        : [...prevExpandedGroups, groupId]
    );
  }

  // Stores a new group into the db
  async function saveGroup() {
    const newName = prompt("Nombre del grupo:");
    if (newName) {
      try {
        const response = await fetch(`${URL}/filter_groups/save`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ name: newName }),
        });

        if (!response.ok) {
          alert("Error al guardar grupo");
          throw new Error("Failed to save group");
        } 
      } 
      
      catch (err) {
        if (err.name === 'TypeError') {
          alert("No se pudo conectar con el servidor.");
        } else { 
          console.error("Error saving group: ", err);
        }
      } 
      
      finally {
        fetchData();
      }
    }
  }

  // Updates a group with the current filter data
  async function updateGroup(id) {
    const { name } = await getGroup(id);
    const newName = prompt("Introduce el nuevo nombre para el grupo:", name);
    if (newName) {
      try {
        const response = await fetch(`${URL}/filter_groups/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ name: newName }),
        });

        if (!response.ok) {
          alert("Error al actualizar grupo");
          throw new Error("Failed to update group");
        } 
      } 
      
      catch (err) {
        if (err.name === 'TypeError') {
          alert("No se pudo conectar con el servidor.");
        } else { 
          console.error("Error updating group: ", err);
        }
      } 
      
      finally {
        fetchData();
      }
    }
  }

  // Deletes a group from the db given an id
  async function deleteGroup(id) {
    const confirmDelete = window.confirm(`Estas a punto de borrar el grupo ${
      groupList.find((group) => group.id === id).name.toUpperCase()
    }`)
    if (!confirmDelete) return;
    

    try {
      const response = await fetch(`${URL}/filter_groups/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        alert("Error al borrar grupo");
        throw new Error("Failed to delete group");
      }
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error deleting group: ", err);
      }
    } 
    
    finally {
      fetchData();
    }
  }

  // Obtains data from a specific group
  async function getGroup(id) {
    try {
      const response = await fetch(`${URL}/filter_groups/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        alert("Error al obtener datos del grupo ");
        throw new Error("Failed to fetch group");
      }

      const data = await response.json();
      return data;
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error fetching group: ", err);
      }
      return null;
    }
  }

  // ------------------------- Display data on parent's table ------------------------- //

  // Obtains the intersection of the filters and the parent's table data
  async function applyFilter(id) {
    applyFilterStyle(id);
    const { filters: filterData } = await getFilter(id);
    if (filterData) {
      try {
        const response = await fetch(`${URL}/tables/apply/filter/${table}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(filterData),
        });

        if (!response.ok) {
          alert("Error al aplicar filtro");
          throw new Error("Failed to apply filter");
        }
        
        const { data, meta } = await response.json();
        setData(data);
        setMeta(meta);
        setFilters({});
      } 
      
      catch (err) {
        if (err.name === 'TypeError') {
          alert("No se pudo conectar con el servidor.");
        } else { 
          console.error("Error applying filters: ", err);
        }
      }
    }
  }

  // Obtains the difference of the filters and the parent's table data
  async function removeFilter(id) {
    removeFilterStyle(id);
    const { filters: filterData } = await getFilter(id);
    if (filterData) {
      try {
        const response = await fetch(`${URL}/tables/remove/filter/${table}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(filterData),
        });

        if (!response.ok) {
          alert("Error al quitar filtro");
          throw new Error("Failed to remove filter");
        }

        const { data, meta } = await response.json();
        setData(data);
        setMeta(meta);
        setFilters({});
      } 
      
      catch (err) {
        if (err.name === 'TypeError') {
          alert("No se pudo conectar con el servidor.");
        } else { 
          console.error("Error removing filters: ", err);
        }
      }
    }
  }

  // Updates the parent's filter list (dropdowns will show data )
  async function showFilter(id) {
    const { filters: filterData } = await getFilter(id);
    if (filterData) setFilters(filterData);
  }

  // Aplies a filter without modifying the table
  const getFilteredTable = async () => {
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/tables/filter/${table}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(filters),
      });

      if (!response.ok) {
        alert("Error al obtener datos del servidor");
        throw new Error("Failed to fetch filtered data");
      }

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error fetching filtered data: ", err);
      }
    } 
    
    finally {
      onUpdateLoading(false);
    }
  };

  // Obtains the intersection of the filters and the parent's table data
  async function applyGroup(id) {
    applyGroupStyle(id);
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/tables/apply/group/${table}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ groupId: id }),
      });

      if (!response.ok) {
        alert("Error al aplicar grupo");
        throw new Error("Failed to apply group");
      }

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
      setFilters({});
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error applying group: ", err);
      }
    } 
    
    finally {
      onUpdateLoading(false);
    }
  }

  // Obtains the difference of the filters and the parent's table data
  async function removeGroup(id) {
    removeGroupStyle(id);
    onUpdateLoading(true);
    try {
      const response = await fetch(`${URL}/tables/remove/group/${table}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ groupId: id }),
      });

      if (!response.ok) {
        alert("Error al quitar grupo");
        throw new Error("Failed to remove group");
      }

      const { data, meta } = await response.json();
      setData(data);
      setMeta(meta);
      setFilters({});
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error removing group: ", err);
      }
    } 
    
    finally {
      onUpdateLoading(false);
    }
  }

  // Creates an execution procedure to apply/remove filters and groups in some order
  async function executeProcedure(executionList) {
    onUpdateLoading(true);
    const filters = executionList.filter((item) => item.type === 'filter');
    const groups = executionList.filter((item) => item.type === 'group');
    const sortedGroups = groups.sort((a, b) => a.count - b.count);

    // Combine and prioritize: first "apply" then "remove"
    const orderedFilters = [
      ...filters.filter((item) => item.action === 'apply'),
      ...filters.filter((item) => item.action === 'remove'),
    ];

    const orderedGroups = [
      ...sortedGroups.filter((item) => item.action === 'apply'),
      ...sortedGroups.filter((item) => item.action === 'remove'),
    ];

    let lastResponse = null

    try {
      // Process filters one by one
      for(const filter of orderedFilters) {
        const { id, action } = filter;

        // Obtain specific filter data
        const { filters: filterData } = await getFilter(id);
        if (!filterData) continue
        
        const response = await fetch(`${URL}/tables/${action}/filter/${table}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(filterData),
        });

        if (!response.ok) {
          alert(`Error al ${action === 'apply' ? 'aplicar' : 'quitar'} filtro`);
          throw new Error(`Failed to ${action} filter`);
        }

        lastResponse = response;
      }

      // Process groups one by one
      for (const group of orderedGroups) {
        const { id, action } = group;
        const response = await fetch(`${URL}/tables/${action}/group/${table}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ groupId: id }),
        });

        if (!response.ok) {
          alert(`Error al ${action === 'apply' ? 'aplicar' : 'quitar'} grupo`);
          throw new Error(`Failed to ${action} group`);
        }

        lastResponse = response;
      }

      if (lastResponse) {
        const { data, meta } = await lastResponse.json();
        setData(data);
        setMeta(meta);
        setFilters({});
      }
    }

    catch(err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error executing procedure: ", err);
      }
    }

    finally {
      onUpdateLoading(false);
    }
  }

  // ------------------------- Hooks ------------------------- //

  // Gets all filters and groups stored in the db
  const fetchData = useCallback(async () => {
    try {
      const filterResponse = await fetch(`${URL}/filters/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!filterResponse.ok) {
        alert("Error al obtener lista de filtros");
        throw new Error("Failed to fetch filters");
      }
      const filterData = await filterResponse.json();

      const groupResponse = await fetch(`${URL}/filter_groups/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!groupResponse.ok) {
        alert("Error al obtener lista de grupos");
        throw new Error("Failed to fetch groups");
      }
      const groupData = await groupResponse.json();

      setFilterList(filterData);
      setGroupList(groupData);
    } 
    
    catch (err) {
      if (err.name === 'TypeError') {
        alert("No se pudo conectar con el servidor.");
      } else { 
        console.error("Error fetching data for FilterList: ", err);
      }
    }
  }, []);

  // Renders component
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // ------------------------- Component ------------------------- //
  return (
    <div>
      <h2>Filtros</h2>
      <div style={{ maxHeight: "20rem", overflow: "auto" }}>
        {/* Filters without group */}
        {filterList
          .filter((filter) => filter.groupId === null)
          .map((filter, index) => (
            <div key={index} style={getStyle(filter.id, "filter")}>
              <label><b>{filter.name}</b></label>
              <button onClick={() => showFilter(filter.id)}>Ver</button>
              <button onClick={() => applyFilter(filter.id)}>Aplicar</button>
              <button onClick={() => removeFilter(filter.id)}>Quitar</button>
              <button onClick={() => handleForm(filter.id)}>Modificar</button>
              <button onClick={() => deleteFilter(filter.id)}>Borrar</button>
            </div>
          ))}

        {/* Groups */}
        {groupList.map((group, groupIndex) => {
          const filterCount = filterList.filter((filter) => filter.groupId === group.id).length;

          return (
            <div key={groupIndex} style={getStyle(group.id, "group")}>
              <label onClick={() => toggleGroup(group.id)}>
                <b>{expandedGroups.includes(group.id) ? "▼" : "▶"} {group.name} ({filterCount})</b>
              </label>
              <button onClick={() => applyGroup(group.id)}>Aplicar</button>
              <button onClick={() => removeGroup(group.id)}>Quitar</button>
              <button onClick={() => updateGroup(group.id)}>Modificar</button>
              <button onClick={() => deleteGroup(group.id)}>Borrar</button>

              {/* Filters of each group */}
              {expandedGroups.includes(group.id) && filterList
                .filter((filter) => filter.groupId === group.id)
                .map((filter, filterIndex) => (
                  <div key={`${groupIndex}-${filterIndex}`} style={getStyle(filter.id, "filter")}>
                    <label>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <b>{filter.name}</b>
                    </label>
                    <button onClick={() => showFilter(filter.id)}>Ver</button>
                    <button onClick={() => applyFilter(filter.id)}>Aplicar</button>
                    <button onClick={() => removeFilter(filter.id)}>Quitar</button>
                    <button onClick={() => handleForm(filter.id)}>Modificar</button>
                    <button onClick={() => deleteFilter(filter.id)}>Borrar</button>
                  </div>
                ))}
            </div>
          );
        })}
      </div>

      <button onClick={() => handleForm(null)}>Guardar filtro</button>
      <button onClick={() => setIsDrawFormActive(true)}>Guardar sorteo</button>
      <br></br>
      <button onClick={saveGroup}>Crear grupo</button>
      <button onClick={() => setIsFilterGroupFormActive(true)}>Crear combinaciones</button>
      <br></br>
      <button onClick={() => setIsProcedureFormActive(true)}>Ejecutar procedimiento</button>
      <br></br>
      <button onClick={getFilteredTable}>Filtrar</button>
      <button onClick={onReset}>Reset</button>
      <br></br>
      <br></br>

      {isSaveFormActive && (
        <SaveFiltersForm
          filters={filters}
          groupList={groupList}
          formData={formData}
          setFormData={setFormData}
          setIsSaveFormActive={setIsSaveFormActive}
          onSave={saveFilter}
          onUpdate={updateFilter}
        />
      )}

      {isDrawFormActive && (
        <SaveCombinationForm 
          groupList={groupList}
          setFormData={setFormData}
          setIsDrawFormActive={setIsDrawFormActive}
          onSave={saveCombination}
        />
      )}

      {isFilterGroupFormActive && (
        <SaveGroupOfFilters 
          setIsFilterGroupFormActive={setIsFilterGroupFormActive}
          onSave={createFilterGroup}
        />
      )}

      {isProcedureFormActive && (
        <ExecuteProcedureForm 
          setIsProcedureFormActive={setIsProcedureFormActive}
          onExecute={executeProcedure}
        />
      )}
    </div>
  );
};

export default FiltersList;
