import { useEffect, useState, useMemo } from "react";
import "../../../css/Forms.css";

const URL = process.env.REACT_APP_API_URL;

const SaveGroupOfFilters = ({ setIsFilterGroupFormActive, onSave }) => {
  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [aviableColumns, setAviableColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [onlyFirst, setOnlyFirst] = useState(true);
  const [leader, setLeader] = useState("")
  const [searchTerm, setSearchTerm] = useState("");

  const leaderArray = ["Lider 1", "Lider 2", "Lider 3", "Lider 4", "Lider 5", "Lider 6", "Lider 7", "Lider 8", "Lider 9", "Lider 10", "Lider 11", "Lider 12", "Lider 13", "Lider 14", "Lider 15", "Lider Resto"]

  // Stores a new filter into the db
  async function saveGroupOfFilters(e) {
    e.preventDefault();

    if (!name) {
      alert("Introduce el nombre del grupo de filtros.");
      return;
    } if (!selectedColumns) {
      alert("Elige por lo menos una columna");
      return;
    } if (!size) {
      alert("Introduce tamaño de las combinaciones.");
      return;
    } if (!leader) {
      alert("Elige un lider para calcular");
      return;
    }

    onSave(name, selectedColumns, Number(size), Number(leader), onlyFirst);
  }

  // Filter column options
  const filteredColumns = useMemo(() => {
    if (leader === "") return Object.keys(aviableColumns);

    return Object.keys(aviableColumns).filter(
      (column) => aviableColumns[column] > leader
    );
  }, [aviableColumns, leader]);

  // Fetch aviable columns when rendering the component
  useEffect(() => {
    async function fetchColumns() {
      try {
        const response = await fetch(`${URL}/tables/columns`, {
          method: "GET",
          headers: {
            "Content-Type": "application/jon",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });

        if (!response.ok) {
          alert("Error al obtener datos del servidor");
          throw new Error("Failed to fetch columns");
        }
  
        const columns = await response.json();
        setAviableColumns(columns);
      }

      catch (err) {
        if (err.name === 'TypeError') {
          alert("No se pudo conectar con el servidor.");
        } else { 
          console.error("Error fetching filtered data: ", err);
        }
      }
    }

    fetchColumns();
  }, []);

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ width: "22%" }}>
        <form onSubmit={saveGroupOfFilters}>
          <div>
            <label>
              <b>Nombre</b>
              <input
                type="string"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Grupo de filtros"
              />
            </label>
          </div>
          
          <div>
            <label>
              <b>Selecciona un lider</b>
              <select
                value={leader}
                onChange={(e) => setLeader(e.target.value)}
              >
                <option value="" disabled>Selecciona un líder...</option>
                {leaderArray.map((leaderName, index) => (
                  <option key={index} value={index}>{leaderName}</option>
                ))}
              </select>
            </label>
          </div>

          <div>
            <label className="checkbox-label">
              <span><b>{onlyFirst ? "Solo lider" : "Todas menos el lider"}</b></span>
              <div>
                  <input
                    type="checkbox"
                    checked={onlyFirst}
                    onChange={(e) => setOnlyFirst(e.target.checked)}
                  />
              </div>
            </label>
          </div>

          <div>
            <label>
              <b>Selecciona columnas</b>
              <input
                type="text"
                placeholder="Buscar columnas..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select
                style={{
                  height: '10rem',
                  textAlign: 'justify',
                  textAlignLast: 'justify',
                }}
                multiple
                value={selectedColumns}
                onChange={(e) =>
                  setSelectedColumns(
                    Array.from(e.target.selectedOptions, (option) => option.value)
                  )
                }
              >
                {filteredColumns.length > 0 ? (
                  filteredColumns.map((column) => (
                    <option key={column} value={column}>
                      {`P${Object.keys(aviableColumns).indexOf(column) + 1} ${column}`}
                    </option>
                  ))
                ) : (
                  <option disabled>No hay columnas que coincidan</option>
                )}
              </select>
            </label>
          </div>

          <div className="chosen-columns">
            <b>{selectedColumns.length > 0 
              ? `Columnas elegidas (${selectedColumns.length}):` 
              : "Columnas elegidas:"}
            </b>
            <span>
              {selectedColumns.length > 0 
                ? selectedColumns.map(column => `P${Object.keys(aviableColumns).indexOf(column) + 1}`).join("\t") 
                : ""}
            </span>
          </div>

          <div>
            <label>
              <b>Tamaño</b>
              <input
                type="number"
                min="1"
                max={selectedColumns.length || 20}
                value={size}
                onChange={(e) => setSize(e.target.value)}
                placeholder="Tamaño combinacion"
              />
            </label>
          </div>

          <div className="button-group">
            <button type="button" onClick={() => setIsFilterGroupFormActive(false)}>Cancelar</button>
            <button type="submit">Guardar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveGroupOfFilters;
